import service from "@/utils/request";
import qs from "qs";
export function list(data) {
  return service({
    method: "get",
    url: "/api/article/queryAll",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function get(id) {
  return service({
    method: "get",
    url: "/api/article/" + id
  });
}