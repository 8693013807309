var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "LayoutBottom" }, [
    _vm._m(0),
    _c("div", { staticClass: "bottom", on: { click: _vm.Jump } }, [
      _c("span", [_vm._v("蜀ICP备2023000758号-1")]),
      _c("span", [_vm._v("公安备案号: 51078102110214")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "currency_box" }, [
      _c("div", { staticClass: "top" }, [
        _c("span", [_vm._v("指导单位： 四川省高新技术产业研究院")]),
        _c("span", [_vm._v("主办单位： 江油高新区")]),
        _c("span", [_vm._v("运营单位： 江油高新区管委会党群部 ")]),
        _c("span", [_vm._v("江油高新区企业服务中心")]),
        _c("span", [_vm._v("四川中科融创科技有限公司")]),
        _c("span", [_vm._v("技术支持： 四川中科融创科技有限公司")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }