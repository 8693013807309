import "E:\\\u4E2D\u79D1\u878D\u521B\\02\u5DE5\u4F5C\\16\u6C5F\u6CB9\u9AD8\u65B0\u533A\u79D1\u521B\u670D\u52A1\u516C\u5171\u5E73\u53F0\\jykct-web\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "E:\\\u4E2D\u79D1\u878D\u521B\\02\u5DE5\u4F5C\\16\u6C5F\u6CB9\u9AD8\u65B0\u533A\u79D1\u521B\u670D\u52A1\u516C\u5171\u5E73\u53F0\\jykct-web\\node_modules\\core-js\\modules\\es.promise.js";
import "E:\\\u4E2D\u79D1\u878D\u521B\\02\u5DE5\u4F5C\\16\u6C5F\u6CB9\u9AD8\u65B0\u533A\u79D1\u521B\u670D\u52A1\u516C\u5171\u5E73\u53F0\\jykct-web\\node_modules\\core-js\\modules\\es.object.assign.js";
import "E:\\\u4E2D\u79D1\u878D\u521B\\02\u5DE5\u4F5C\\16\u6C5F\u6CB9\u9AD8\u65B0\u533A\u79D1\u521B\u670D\u52A1\u516C\u5171\u5E73\u53F0\\jykct-web\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // 全局样式

import '@/assets/css/index.css'; // element

import ElementUI from 'element-ui';
import './theme/index.css';
Vue.use(ElementUI); // cookies

import VueCookies from 'vue-cookies';
Vue.use(VueCookies); // 数据字典

import dict from '@/components/Dit'; // 年月日时间戳转换

import fuzzyTime from "@/utils/fuzzyTime";
Vue.use(fuzzyTime); // 序列化

import qs from 'qs';
Vue.use(qs); // 阿里巴巴 矢量库

import '@/assets/iconfont/iconfont';
import '@/assets/iconfont/iconfont.css'; // 回到顶部

router.afterEach(function (to, from, next) {
  window.scrollTo(0, 0);
}); // 数据滚动插件

import scroll from 'vue-seamless-scroll';
Vue.use(scroll);
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(dict);
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');