var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LayoutTop",
    { attrs: { activeNav: "首页" } },
    [
      _c(
        "el-carousel",
        { staticClass: "one", attrs: { height: "280px" } },
        _vm._l(_vm.oneList, function (item, index) {
          return _c("el-carousel-item", { key: index }, [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: item.pic, alt: "" },
              on: {
                click: function ($event) {
                  item.isJump ? _vm.jump(item.jumpUrl) : ""
                },
              },
            }),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "two currency_box" }, [
        _c("ul", [
          _c("li", [
            _c("div", [
              _c(
                "p",
                [
                  _c("CountTo", {
                    staticClass: "two_num",
                    attrs: {
                      startVal: 0,
                      endVal: _vm.twoList.innovationPolicyCount,
                      duration: 2000,
                    },
                  }),
                  _c("span", [_vm._v("项")]),
                ],
                1
              ),
              _c("p", [
                _vm._v(" 创新政策推送多达"),
                _c("span", [_vm._v(_vm._s(_vm.twoList.innovationPolicyCount))]),
                _vm._v("项 "),
              ]),
            ]),
          ]),
          _c("li", [
            _c("div", [
              _c(
                "p",
                [
                  _c("CountTo", {
                    staticClass: "two_num",
                    attrs: {
                      startVal: 0,
                      endVal: _vm.twoList.detectCount,
                      duration: 2000,
                    },
                  }),
                  _c("span", [_vm._v("个")]),
                ],
                1
              ),
              _c("p", [
                _vm._v(" 多达"),
                _c("span", [_vm._v(_vm._s(_vm.twoList.detectCount))]),
                _vm._v("个检验检测指标 "),
              ]),
            ]),
          ]),
          _c("li", [
            _c("div", [
              _c(
                "p",
                [
                  _c("CountTo", {
                    staticClass: "two_num",
                    attrs: {
                      startVal: 0,
                      endVal: _vm.twoList.loanitemCount,
                      duration: 2000,
                    },
                  }),
                  _c("span", [_vm._v("个")]),
                ],
                1
              ),
              _c("p", [
                _vm._v(" 提供科技金融产品"),
                _c("span", [_vm._v(_vm._s(_vm.twoList.loanitemCount))]),
                _vm._v("个 "),
              ]),
            ]),
          ]),
          _c("li", [
            _c("div", [
              _c(
                "p",
                [
                  _c("CountTo", {
                    staticClass: "two_num",
                    attrs: {
                      startVal: 0,
                      endVal: _vm.twoList.achievementCount,
                      duration: 2000,
                    },
                  }),
                  _c("span", [_vm._v("个")]),
                ],
                1
              ),
              _c("p", [
                _vm._v(" 创新成果数多达"),
                _c("span", [_vm._v(_vm._s(_vm.twoList.achievementCount))]),
                _vm._v("个 "),
              ]),
            ]),
          ]),
          _c("li", [
            _c("div", [
              _c(
                "p",
                [
                  _c("CountTo", {
                    staticClass: "two_num",
                    attrs: {
                      startVal: 0,
                      endVal: _vm.twoList.companyCount,
                      duration: 2000,
                    },
                  }),
                  _c("span", [_vm._v("个")]),
                ],
                1
              ),
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.twoList.companyCount))]),
                _vm._v("个优质用户加入 "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "three" }, [
        _c(
          "div",
          { staticClass: "currency_box three_box" },
          [
            _c(
              "el-carousel",
              { staticClass: "left", attrs: { height: "306px" } },
              _vm._l(_vm.Carousel, function (item, id) {
                return _c("el-carousel-item", { key: id }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: "/currencyDetails",
                            query: { id: item.id },
                          })
                        },
                      },
                    },
                    [
                      _c("img", { attrs: { src: item.pic, alt: "" } }),
                      _c("div", { staticClass: "left_title" }, [
                        _c("p", { staticClass: "ellipsis" }, [
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ]),
                      ]),
                    ]
                  ),
                ])
              }),
              1
            ),
            _c("div", { staticClass: "right" }, [
              _c(
                "ul",
                { staticClass: "top" },
                _vm._l(_vm.statuteTypeData, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: { statuteCheck: item.label === _vm.statute },
                      on: {
                        click: function ($event) {
                          return _vm.choiceStatute(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                0
              ),
              _c(
                "ul",
                { staticClass: "bottom" },
                _vm._l(_vm.statuteData, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: { statuteCheck: item.name === _vm.statute },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: "/currencyDetails",
                            query: { id: item.id },
                          })
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "ellipsis" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("p", [
                        _vm._v(_vm._s(_vm.fuzzyTimeHorizon(item.releaseTime))),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "p",
                {
                  staticClass: "more_one",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "focusNews",
                        params: { id: _vm.statuteId },
                      })
                    },
                  },
                },
                [_vm._v(" 查看更多> ")]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "four currency_box clearfix" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/home_jump_one.png"), alt: "" },
          on: {
            click: function ($event) {
              return _vm.jump("http://www.scgxqpt.com/index.html")
            },
          },
        }),
        _c("img", {
          attrs: { src: require("@/assets/img/home_jump_two.png"), alt: "" },
          on: {
            click: function ($event) {
              return _vm.jump("http://www.scgxqpt.com/web/gx002/index.html")
            },
          },
        }),
      ]),
      _c("div", { staticClass: "six currency_box" }, [
        _c("div", { staticClass: "left currency_box" }, [
          _c("div", { staticClass: "currency_style_box" }, [
            _c("div", { staticClass: "left_currency_style_box" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/img/smallStyle.png"),
                  alt: "",
                },
              }),
              _c("p", { staticClass: "title_one" }, [_vm._v("创新政策")]),
              _c("p", { staticClass: "title_two" }, [
                _vm._v("面向企业精准推送科技创新类政策公告"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "right_currency_style_box",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/policy")
                  },
                },
              },
              [_vm._v(" 查看更多 > ")]
            ),
          ]),
          _c(
            "ul",
            _vm._l(_vm.sixLeft, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/policyDetails",
                        query: { id: item.id },
                      })
                    },
                  },
                },
                [
                  item.days != "已结束"
                    ? _c("p", { staticClass: "six_img_one" }, [
                        _vm._v("申报中"),
                      ])
                    : _c("p", { staticClass: "six_img_two" }, [
                        _vm._v("已结束"),
                      ]),
                  _c("p", { staticClass: "ellipsis" }, [
                    item.days != "已结束"
                      ? _c("span", [
                          _vm._v("【剩余："),
                          _c("span", [_vm._v(_vm._s(item.days + "天"))]),
                          _vm._v(" 】"),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "six_img_three" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "right currency_box" }, [
          _c("div", { staticClass: "currency_style_box" }, [
            _c("div", { staticClass: "left_currency_style_box" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/img/smallStyle.png"),
                  alt: "",
                },
              }),
              _c("p", { staticClass: "title_one" }, [_vm._v("政企互动")]),
              _c("p", { staticClass: "title_two" }, [
                _vm._v("您的疑问，我们解读！"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "right_currency_style_box",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/interaction")
                  },
                },
              },
              [_vm._v(" 查看更多 > ")]
            ),
          ]),
          _c("div", { staticClass: "six_right_content_box" }, [
            _c("ul", [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/letterAdd",
                        query: { type: "CONSULTING" },
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon-suggest.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("咨询")]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/letterAdd",
                        query: { type: "COMPLAINTS" },
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon-complaints.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("投诉")]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/letterAdd",
                        query: { type: "SUGGEST" },
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon-consulting.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("建言")]),
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/letterAdd",
                        query: { type: "REPORT" },
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon-report.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("举报")]),
                ]
              ),
            ]),
            _c(
              "ul",
              [
                _c("div", [_vm._v("信件选登")]),
                _vm._l(_vm.sixRight, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path: "/lettersDetails",
                            query: { id: item.id },
                          })
                        },
                      },
                    },
                    [
                      _c("p"),
                      _c("p", { staticClass: "ellipsis" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "seven" }, [
        _c("div", { staticClass: "currency_box seven_box" }, [
          _c("div", { staticClass: "currency_style_box" }, [
            _c("div", { staticClass: "left_currency_style_box" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/img/smallStyle.png"),
                  alt: "",
                },
              }),
              _c("p", { staticClass: "title_one" }, [_vm._v("企业展示")]),
              _c("p", { staticClass: "title_two" }, [
                _vm._v("凝聚产品服务、提升沟通交流、促进行业发展"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "right_currency_style_box",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/exhibition")
                  },
                },
              },
              [_vm._v(" 查看更多 > ")]
            ),
          ]),
          _c("div", { staticClass: "seven_top" }, [
            _c("div", { staticClass: "seven_top_img" }, [
              _c("p", [_vm._v("热门企业")]),
              _c("p", [_vm._v("促进对接 提升发展")]),
              _c(
                "p",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/user_details",
                        query: { type: 10 },
                      })
                    },
                  },
                },
                [_vm._v(" 我要入驻 ")]
              ),
            ]),
            _c(
              "ul",
              _vm._l(_vm.sevenTop, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          path: "/exhibitionDetails",
                          query: { id: item.id },
                        })
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: item.pic + "",
                        alt: "",
                        onerror: _vm.defaultImg,
                      },
                    }),
                    _c("div", [
                      _c("p", [_vm._v(_vm._s(item.companyName))]),
                      _c("p", [_vm._v("所在地址：" + _vm._s(item.address))]),
                      _c("p", [
                        _vm._v("行业领域：" + _vm._s(item.companyTypeName)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "seven_bottom" }, [
            _c("div", { staticClass: "currency_style_box" }, [
              _c("div", { staticClass: "left_currency_style_box" }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/img/smallStyle.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "title_one" }, [_vm._v("技术转移")]),
                _c("p", { staticClass: "title_two" }, [
                  _c(
                    "span",
                    {
                      class: { active: _vm.sevenBottomType == 2 },
                      on: {
                        click: function ($event) {
                          return _vm.clickSevenBottomType(2)
                        },
                      },
                    },
                    [_vm._v("创新需求")]
                  ),
                  _c("span", [_vm._v("/")]),
                  _c(
                    "span",
                    {
                      class: { active: _vm.sevenBottomType == 1 },
                      on: {
                        click: function ($event) {
                          return _vm.clickSevenBottomType(1)
                        },
                      },
                    },
                    [_vm._v("创新成果")]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "right_currency_style_box",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/technologyList",
                        query: { type: _vm.sevenBottomType },
                      })
                    },
                  },
                },
                [_vm._v(" 查看更多 > ")]
              ),
            ]),
            _c("div", { staticClass: "currency_content_box" }, [
              _c(
                "ul",
                { staticClass: "currency_content_box_left" },
                _vm._l(_vm.sevenBottom, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          _vm.sevenBottomType == 1
                            ? _vm.$router.push({
                                path: "/technologyDetails",
                                query: { type: 1, id: item.id },
                              })
                            : _vm.$router.push({
                                path: "/technologyDetails",
                                query: { type: 2, id: item.id },
                              })
                        },
                      },
                    },
                    [
                      _c("div", [_vm._v(_vm._s(item.name))]),
                      _c("div", [_vm._v(_vm._s(item.typeName))]),
                      _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              item.price == 0 ? "面议" : item.price + "万元"
                            )
                          ),
                        ]),
                        _c("p", [_vm._v("我有意向")]),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "currency_content_box_right" },
                [
                  _c("p", { staticClass: "title" }, [_vm._v("最新成交")]),
                  _c(
                    "vue-seamless-scroll",
                    {
                      staticClass: "scroll_box",
                      attrs: {
                        data: _vm.CarouselData,
                        "class-option": _vm.defaultOption,
                      },
                    },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.CarouselData, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "multilineEllipsis",
                              attrs: { "data-index": index },
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fuzzyTimeHorizon(item.createTime)
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.consumer +
                                        "与" +
                                        item.provider +
                                        "达成合作"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "btn" }, [
                    _vm.sevenBottomType == 2
                      ? _c(
                          "p",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  path: "/release",
                                  query: {
                                    type: 2,
                                  },
                                })
                              },
                            },
                          },
                          [_vm._v(" 发布需求 ")]
                        )
                      : _c(
                          "p",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  path: "/release",
                                  query: {
                                    type: 1,
                                  },
                                })
                              },
                            },
                          },
                          [_vm._v(" 发布成果 ")]
                        ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "home_title" }),
      _c("div", { staticClass: "currency_style_box currency_box" }, [
        _c("div", { staticClass: "left_currency_style_box" }, [
          _c("img", {
            attrs: { src: require("../assets/img/smallStyle.png"), alt: "" },
          }),
          _c("p", { staticClass: "title_one" }, [_vm._v("科技金融")]),
          _c("p", { staticClass: "title_two" }, [
            _vm._v("促进科技开发、成果转化、高新技术企业发展"),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "right_currency_style_box",
            on: {
              click: function ($event) {
                return _vm.$router.push("/finance")
              },
            },
          },
          [_vm._v(" 查看更多 > ")]
        ),
      ]),
      _c("div", { staticClass: "eight" }, [
        _c(
          "ul",
          { staticClass: "currency_box" },
          _vm._l(_vm.eightList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      path: "/financeDetails",
                      query: {
                        id: item.id,
                      },
                    })
                  },
                },
              },
              [
                _c("p", [_vm._v(_vm._s(item.typeName))]),
                _c("p", [_vm._v(_vm._s(item.itemName))]),
                _c("p", [_vm._v(_vm._s(item.organ))]),
                _c("p", [_vm._v(_vm._s("参考年利率：" + item.profit))]),
                _c("p", [_vm._v("进入详情")]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "currency_style_box currency_box" }, [
        _c("div", { staticClass: "left_currency_style_box" }, [
          _c("img", {
            attrs: { src: require("../assets/img/smallStyle.png"), alt: "" },
          }),
          _c("p", { staticClass: "title_one" }, [_vm._v("友情链接")]),
        ]),
      ]),
      _c(
        "ul",
        { staticClass: "nine currency_box" },
        _vm._l(_vm.nineList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "ellipsis",
              on: {
                click: function ($event) {
                  return _vm.jump(item.url)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "contact_box" }, [
        _c("p", [
          _c("img", {
            attrs: { src: require("@/assets/img/home_phone.png"), alt: "" },
          }),
          _vm._v(" 咨询电话："),
        ]),
        _c("p", [_vm._v("199-1530-4640")]),
        _c("p", [_vm._v("181-2189-8832")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }