import service from "@/utils/request";
import qs from "qs";
export function add(data) {
  return service({
    method: "post",
    url: "/api/company",
    data: data
  });
}
export function get(id) {
  return service({
    method: "get",
    url: "/api/company/" + id
  });
}
export function put(data) {
  return service({
    method: "put",
    url: "/api/company",
    data: data
  });
}
export function addProduct(data) {
  return service({
    method: "post",
    url: "/api/product",
    data: data
  });
}
export function putProduct(data) {
  return service({
    method: "put",
    url: "/api/product",
    data: data
  });
}
export function delProduct(data) {
  return service({
    method: "delete",
    url: "/api/product",
    data: data
  });
}
export function list(data) {
  return service({
    method: "get",
    url: "/api/company",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}