// 专门用来操作cookie的方法包
// 内部封装了繁琐的操作方法 参数处理 暴露三个函数 get,set,remove
import cookie from 'vue-cookies'; //引入

var token = 'token';
var user = 'user';
var news = 'news'; // token

export function getToken() {
  return cookie.get(token);
}
export function setToken(val) {
  return cookie.set(token, val, '0');
}
export function removeToken() {
  return cookie.remove(token);
} // user

export function getUser() {
  return cookie.get(user);
}
export function setUser(val) {
  return cookie.set(user, val, '0');
}
export function removeUser() {
  return cookie.remove(user);
} // news

export function getNews() {
  return cookie.get(news);
}
export function setNews(val) {
  return cookie.set(news, val, '0');
}
export function removeNews() {
  return cookie.remove(news);
}