import "core-js/modules/es.array.concat.js";
import request from "@u/request";
import qs from 'qs'; // 获取部门列表

export function getDeptList() {
  return request({
    url: '/api/dept?&lettersCheck=true',
    method: 'get'
  });
} // 新增信件

export function add(data) {
  return request({
    url: '/api/letters',
    method: 'post',
    data: data
  });
}
export function get(id) {
  return request({
    url: 'api/letters/' + id,
    method: 'get'
  });
}
export function getPhoneCode(phone, type) {
  return request({
    url: "/api/verifyCode?phone=".concat(phone, "&type=").concat(type),
    method: 'get'
  });
}
export function getList(data) {
  return request({
    url: "/api/letters/list?" + qs.stringify(data, {
      indices: false
    }),
    method: 'get'
  });
}
export function getByCode(code) {
  return request({
    url: '/api/letters/code/' + code,
    method: 'get'
  });
}
export function getHomeList() {
  return request({
    url: "/api/letters/home",
    method: 'get'
  });
}