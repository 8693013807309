import request from '@/utils/request';
export function get(dictName) {
  var params = {
    dictName: dictName,
    page: 0,
    size: 9999
  };
  return request({
    url: '/api/dictDetail',
    method: 'get',
    params: params
  });
}
export function getDictMap(dictName) {
  var params = {
    dictName: dictName,
    page: 0,
    size: 9999
  };
  return request({
    url: '/api/dictDetail/map',
    method: 'get',
    params: params
  });
}
export function add(data) {
  return request({
    url: '/api/dictDetail',
    method: 'post',
    data: data
  });
}
export function del(id) {
  return request({
    url: '/api/dictDetail/' + id,
    method: 'delete'
  });
}
export function edit(data) {
  return request({
    url: '/api/dictDetail',
    method: 'put',
    data: data
  });
}
export default {
  add: add,
  edit: edit,
  del: del
};