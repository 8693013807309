import Vue from 'vue';
import Vuex from 'vuex';
import { setToken, getToken, getUser, setUser, getNews, setNews as _setNews } from '@/utils/getUserData.js';
Vue.use(Vuex);
export default new Vuex.Store({
  // 初始
  state: {
    user: getUser() || null,
    token: getToken() || null,
    baseApi: process.env.VUE_APP_URL,
    fileUploadApi: process.env.VUE_APP_URL + '/api/localStorage',
    nwes: getNews() || null
  },
  // 改变 state 的值
  mutations: {
    setData: function setData(state, val) {
      state.token = val;
      setToken(val);
    },
    setUsers: function setUsers(state, val) {
      state.user = val;
      setUser(val);
    },
    setNews: function setNews(state, val) {
      state.nwes = val;

      _setNews(val);
    }
  },
  // 方法 异步
  actions: {},
  // 过滤
  getters: {}
});