import { Message } from 'element-ui';
export default function auth(_ref) {
  var to = _ref.to,
      next = _ref.next,
      token = _ref.token;

  if (!token || token == 'null') {
    Message.warning("请先登录");
    return next({
      name: 'Login',
      query: {
        to: to.fullPath
      }
    });
  } else {
    return next();
  }
}