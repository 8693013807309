//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {};
  },
  methods: {
    Jump: function Jump() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    }
  }
};