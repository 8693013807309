import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import axios from "axios";
import router from "@/router";
import { Message } from 'element-ui';
import { getToken } from "@/utils/getUserData"; // 创建axios实例

var service = axios.create({
  timeout: 100000 // 请求超时时间

}); // 请求拦截器

service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers["Authorization"] = getToken();
  }

  return config;
}, function (error) {
  return Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (response) {
  return Promise.resolve(response.data);
}, function (error) {
  if (error.response) {
    if (error.response.status === 400) {
      Message.error(error.response.data.message);
    } else if (error.response.status === 401 || error.response.status === 403) {
      router.replace("/Login");
      Message.warning('请先登录');
    } else {
      router.replace("/error?code=".concat(error.response.status));
    }

    return Promise.reject(error);
  }
});
export default service;