import service from "@/utils/request";
import qs from "qs";
export function cgList(data) {
  return service({
    method: "get",
    url: "/api/achievement",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function getCg(id) {
  return service({
    method: "get",
    url: "/api/achievement/" + id
  });
}
export function addCg(data) {
  return service({
    method: "post",
    url: "/api/achievement",
    data: data
  });
}
export function xqList(data) {
  return service({
    method: "get",
    url: "/api/demand",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function getXq(id) {
  return service({
    method: "get",
    url: "/api/demand/" + id
  });
}
export function addXq(data) {
  return service({
    method: "post",
    url: "/api/demand",
    data: data
  });
}
export function yfList(data) {
  return service({
    method: "get",
    url: "/api/design",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function getYf(id) {
  return service({
    method: "get",
    url: "/api/design/" + id
  });
}
export function myCg(data) {
  return service({
    method: "get",
    url: "/api/achievement/user/list",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function myXq(data) {
  return service({
    method: "get",
    url: "/api/demand/user",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function delCg(data) {
  return service({
    method: "delete",
    url: "/api/achievement",
    data: data
  });
}
export function delXq(data) {
  return service({
    method: "delete",
    url: "/api/demand",
    data: data
  });
}
export function putCg(data) {
  return service({
    method: "put",
    url: "/api/achievement",
    data: data
  });
}
export function putXq(data) {
  return service({
    method: "put",
    url: "/api/demand",
    data: data
  });
}