import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import LayoutBottom from "@/components/LayoutBottom.vue";
import { out } from "@/assets/api/Login";
import { list } from "@/assets/api/information";
export default {
  props: {
    activeNav: {
      type: String
    },
    name: {
      type: String
    }
  },
  components: {
    LayoutBottom: LayoutBottom
  },
  data: function data() {
    return {
      WhereToData: [],
      input: JSON.parse(sessionStorage.getItem("input")),
      navaData: [{
        name: "首页",
        path: "/"
      }, {
        name: "新闻资讯",
        path: "/focusNews"
      }, {
        name: "创新政策",
        path: "/policy"
      }, {
        name: "检验检测",
        path: "/testing"
      }, {
        name: "科技金融",
        path: "/finance"
      }, // {
      //   name: "创业孵化",
      //   path: "/hatch",
      // },
      {
        name: "技术转移",
        path: "/technology"
      }, {
        name: "企业展示",
        path: "/exhibition"
      }, {
        name: "政企互动",
        path: "/interaction"
      }, {
        name: "高新区简介",
        path: "/introduce"
      }],
      title: null
    };
  },
  created: function created() {
    var _this = this;

    list({
      isRead: 0
    }).then(function (res) {
      _this.$store.commit("setNews", res.notReaCount);
    });
  },
  mounted: function mounted() {
    if (this.name === undefined) {
      this.title = "时政要闻";
      this.WhereToData = [{
        name: "时政要闻"
      }, {
        name: "政策法规"
      }, {
        name: "通知公告"
      }, {
        name: "创新政策"
      }, {
        name: "检测能力"
      }, {
        name: "科技金融"
      }, {
        name: "创新成果"
      }, {
        name: "创新需求"
      }, {
        name: "企业展示"
      }, {
        name: "政企互动"
      }];
    } else {
      this.title = this.name;

      if (this.$route.name === "technology") {
        this.WhereToData = [{
          name: "创新成果"
        }, {
          name: "创新需求"
        }];
      } else if (this.$route.name === "focusNews") {
        this.WhereToData = [{
          name: "时政要闻"
        }, {
          name: "政策法规"
        }, {
          name: "通知公告"
        }];
      }
    }
  },
  methods: {
    search: function search() {
      if (this.title === "时政要闻") {
        this.$router.push({
          name: "again",
          params: {
            id: 46,
            blurry: this.input,
            name: "focusNews",
            wzName: this.title
          }
        });
      } else if (this.title === "政策法规") {
        this.$router.push({
          name: "again",
          params: {
            id: 47,
            blurry: this.input,
            name: "focusNews",
            wzName: this.title
          }
        });
      } else if (this.title === "通知公告") {
        this.$router.push({
          name: "again",
          params: {
            id: 48,
            blurry: this.input,
            name: "focusNews",
            wzName: this.title
          }
        });
      } else if (this.title === "创新政策") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "policy"
          }
        });
      } else if (this.title === "检测机构") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "testing",
            show: 0,
            wzName: this.title
          }
        });
      } else if (this.title === "检测能力") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "testing",
            show: 1,
            wzName: this.title
          }
        });
      } else if (this.title === "科技金融") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "finance"
          }
        });
      } else if (this.title === "创新成果") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "technologyList",
            type: 1,
            wzName: this.title
          }
        });
      } else if (this.title === "创新需求") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "technologyList",
            type: 2,
            wzName: this.title
          }
        });
      } else if (this.title === "企业展示") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "exhibition"
          }
        });
      } else if (this.title === "政企互动") {
        this.$router.push({
          name: "again",
          params: {
            blurry: this.input,
            name: "lettersQuery"
          }
        });
      }

      sessionStorage.setItem("input", JSON.stringify(this.input));
    },
    signOut: function signOut() {
      var _this2 = this;

      out().then(function (res) {
        _this2.$store.commit("setData", "");

        _this2.$store.commit("setUsers", "");

        _this2.$store.commit("setNews", "");

        _this2.$message.success("账号已退出");

        _this2.$router.push("/");
      });
    },
    jump: function jump(path) {
      this.$router.push(path);
    }
  }
};