import service from "@/utils/request";
import qs from "qs";
export function list(data) {
  return service({
    method: "get",
    url: "/api/Message",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
}
export function modify(data) {
  return service({
    method: "put",
    url: "/api/Message/setRead/{uuIds}",
    data: data
  });
}
export function del(data) {
  return service({
    method: "delete",
    url: "/api/Message",
    data: data
  });
}