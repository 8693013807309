require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.string.split.js");

exports.install = function (Vue, options) {
  Vue.prototype.fuzzyTime = function (time) {
    var time = new Date(parseInt(time)).toLocaleString().split(' ')[0].replace('/', '年').replace('/', '月') + '日';
    return time;
  };

  Vue.prototype.fuzzyTimePoint = function (time) {
    var time = new Date(parseInt(time)).toLocaleString().split(' ')[0].replace('/', '.').replace('/', '.');
    return time;
  };

  Vue.prototype.fuzzyTimeHorizon = function (time) {
    var date = new Date(parseInt(time));
    var y = date.getFullYear();
    var MM = date.getMonth() + 1;
    MM = MM < 10 ? '0' + MM : MM;
    var d = date.getDate();
    d = d < 10 ? '0' + d : d;
    var h = date.getHours();
    h = h < 10 ? '0' + h : h;
    var m = date.getMinutes();
    return y + '-' + MM + '-' + d;
  };

  Vue.prototype.detailedTime = function (time) {
    if (typeof time == 'undefined') {
      return '';
    } else {
      var date = new Date(parseInt(time));
      var y = date.getFullYear();
      var MM = date.getMonth() + 1;
      MM = MM < 10 ? '0' + MM : MM;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      var h = date.getHours();
      h = h < 10 ? '0' + h : h;
      var m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      var s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
    }
  };

  Vue.prototype.detailedTimeOne = function (time) {
    if (typeof time == 'undefined') {
      return '';
    } else {
      var date = new Date(parseInt(time));
      var y = date.getFullYear();
      var MM = date.getMonth() + 1;
      MM = MM < 10 ? '0' + MM : MM;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      var h = date.getHours();
      h = h < 10 ? '0' + h : h;
      var m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      var s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return y + '.' + MM + '.' + d + ' ' + h + ':' + m;
    }
  };

  Vue.prototype.spotTime = function (time) {
    if (typeof time == 'undefined') {
      return '';
    } else {
      var date = new Date(parseInt(time));
      var y = date.getFullYear();
      var MM = date.getMonth() + 1;
      MM = MM < 10 ? '0' + MM : MM;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      return MM + '.' + d;
    }
  };

  Vue.prototype.YearsTime = function (time) {
    if (typeof time == 'undefined') {
      return '';
    } else {
      var date = new Date(parseInt(time));
      var y = date.getFullYear();
      return y + '年';
    }
  };

  Vue.prototype.toTop = function () {
    var top = document.documentElement.scrollTop || document.body.scrollTop; // 实现滚动效果

    var timeTop = setInterval(function () {
      document.body.scrollTop = document.documentElement.scrollTop = top -= 50; // 可以设置回转的高度

      if (top <= 0) {
        clearInterval(timeTop);
      }
    }, 10);
  };
};