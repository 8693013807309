import service from "@/utils/request";
import qs from "qs"; // 字典

export function dictionaries(params) {
  return service({
    method: "get",
    url: "/api/dictDetail/map",
    params: params
  });
} // 用户信息

export function myData(params) {
  return service({
    method: "get",
    url: "/api/users/getDetails",
    params: params
  });
}
export function modifyUser(data) {
  return service({
    method: "put",
    url: "/api/users",
    data: data
  });
} // 收藏

export function newCollection(data) {
  return service({
    method: "post",
    url: "/api/collection",
    data: data
  });
}
export function delCollection(data) {
  return service({
    method: "delete",
    url: "/api/collection",
    data: data
  });
}
export function listCollection(data) {
  return service({
    method: "get",
    url: "/api/collection",
    params: data
  });
} // 认证

export function addAuthentication(data) {
  return service({
    method: "post",
    url: "/api/authentication",
    data: data
  });
} // 修改认证

export function putAuthentication(data) {
  return service({
    method: "put",
    url: "/api/authentication",
    data: data
  });
} // 修改密码

export function ChangePassword(data) {
  return service({
    method: "post",
    url: "/auth/update",
    data: data
  });
} // 友情链接

export function friendship(data) {
  return service({
    method: "get",
    url: "/api/link",
    params: data,
    paramsSerializer: function paramsSerializer(params) {
      return qs.stringify(params, {
        skipNulls: true,
        indices: false
      });
    }
  });
} // 统计

export function Statistics() {
  return service({
    method: "get",
    url: "/api/statistics/count"
  });
} // 设置访问量

export function visit() {
  return service({
    method: "put",
    url: "/api/statistics/setVisitNum"
  });
} // 成交列表 

export function completeList() {
  return service({
    method: "get",
    url: "/api/demand/list"
  });
} // 轮播列表

export function rotation() {
  return service({
    method: "get",
    url: "/api/rotation"
  });
}