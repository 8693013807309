var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "big_box" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "box_head" }, [
            _c("div", { staticClass: "box_head_top currency_box" }, [
              _c("div", { staticClass: "box_head_top_left_box" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../assets/img/logo.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("四川江油高新科创网")]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "search_box" },
                  [
                    _c("div", { staticClass: "left_box" }, [
                      _c("p", { staticClass: "left" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " "),
                        _c("i", { staticClass: "iconfont icon-xiala" }),
                      ]),
                      _c("div", { staticClass: "search_data_box" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.WhereToData, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: {
                                  WhereToActive: item.name === _vm.title,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.title = item.name
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入关键词进行检索" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.search.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.input,
                        callback: function ($$v) {
                          _vm.input = $$v
                        },
                        expression: "input",
                      },
                    }),
                    _c(
                      "p",
                      { staticClass: "right", on: { click: _vm.search } },
                      [_vm._v("搜 索")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "box_head_top_right_box" }, [
                _vm.$store.state.user
                  ? _c(
                      "div",
                      {
                        staticClass: "news_box",
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/user/information")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/news.png"),
                            alt: "",
                          },
                        }),
                        _vm.$store.state.nwes && _vm.$store.state.nwes != 0
                          ? _c("p", [
                              _vm._v(" " + _vm._s(_vm.$store.state.nwes) + " "),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "user_box" }, [
                  !_vm.$store.state.user
                    ? _c("p", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$router.push("/login")
                              },
                            },
                          },
                          [_vm._v("登录")]
                        ),
                        _c("span", [_vm._v("|")]),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$router.push("/register")
                              },
                            },
                          },
                          [_vm._v("注册")]
                        ),
                      ])
                    : _c("p", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$router.push("/user/personal")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$store.state.user.nickName))]
                        ),
                        _c("span", [_vm._v("|")]),
                        _c("span", { on: { click: _vm.signOut } }, [
                          _vm._v("退出"),
                        ]),
                      ]),
                ]),
              ]),
            ]),
            _c(
              "ul",
              { staticClass: "box_head_bottom currency_box" },
              _vm._l(_vm.navaData, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: { active: item.name === _vm.activeNav },
                    on: {
                      click: function ($event) {
                        return _vm.jump(item.path)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              0
            ),
          ]),
          _vm._t("default"),
        ],
        2
      ),
      _c("LayoutBottom"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }