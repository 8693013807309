import _objectSpread from "E:\\\u4E2D\u79D1\u878D\u521B\\02\u5DE5\u4F5C\\16\u6C5F\u6CB9\u9AD8\u65B0\u533A\u79D1\u521B\u670D\u52A1\u516C\u5171\u5E73\u53F0\\jykct-web\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home.vue';
import auth from '@/utils/auth';
import { getToken } from '@/utils/getUserData';
Vue.use(VueRouter);
var routes = [{
  path: '/',
  name: 'Home',
  component: Home
}, {
  path: "/meeting",
  name: "meeting",
  component: function component() {
    return import("@/views/meeting/index.vue");
  },
  meta: {
    title: "会议"
  }
}, {
  path: "/signIn",
  name: "signIn",
  component: function component() {
    return import("@/views/meeting/signIn/index.vue");
  },
  meta: {
    title: "会议签到"
  }
}, {
  path: "/meetingList",
  name: "meetingList",
  component: function component() {
    return import("@/views/meeting/meetingList/index.vue");
  },
  meta: {
    title: "会议列表"
  }
}, {
  path: "/evaluate",
  name: "evaluate",
  component: function component() {
    return import("@/views/meeting/evaluate/index.vue");
  },
  meta: {
    title: "评价"
  }
}, {
  path: "/evaluationSuccessful",
  name: "evaluationSuccessful",
  component: function component() {
    return import("@/views/meeting/evaluationSuccessful/index.vue");
  },
  meta: {
    title: "评价成功"
  }
}, {
  path: "/mobilePhone",
  name: "mobilePhone",
  component: function component() {
    return import("@/views/mobilePhone/index.vue");
  },
  meta: {
    title: "手机页面"
  }
}, {
  path: "/success_page",
  name: "success_page",
  component: function component() {
    return import("@/views/mobilePhone/success_page.vue");
  },
  meta: {
    title: "手机成功页面"
  }
}, {
  path: "/Login",
  name: "Login",
  component: function component() {
    return import("@/views/Login.vue");
  },
  meta: {
    title: "登录"
  }
}, {
  path: "/register",
  name: "register",
  component: function component() {
    return import("@/views/register.vue");
  },
  meta: {
    title: "注册"
  }
}, {
  path: "/policy",
  name: "policy",
  component: function component() {
    return import("@/views/policy/index.vue");
  },
  meta: {
    title: "创新政策"
  }
}, {
  path: "/policyDetails",
  name: "policyDetails",
  component: function component() {
    return import("@/views/policy/details.vue");
  },
  meta: {
    title: "政策详情"
  }
}, {
  path: "/focusNews",
  name: "focusNews",
  component: function component() {
    return import("@/views/Journalism/index.vue");
  },
  meta: {
    title: "新闻列表"
  }
}, {
  path: "/currencyDetails",
  name: "currencyDetails",
  component: function component() {
    return import("@/views/Journalism/details.vue");
  },
  meta: {
    title: "新闻详情"
  }
}, {
  path: "/blank",
  name: "blank",
  component: function component() {
    return import("@/views/Journalism/blank.vue");
  },
  meta: {
    title: "文章空白页面"
  }
}, {
  path: "/finance",
  name: "finance",
  component: function component() {
    return import("@/views/finance/index.vue");
  },
  meta: {
    title: "科技金融列表"
  }
}, {
  path: "/financeDetails",
  name: "financeDetails",
  component: function component() {
    return import("@/views/finance/details.vue");
  },
  meta: {
    title: "科技金融详情"
  }
}, {
  path: "/testing",
  name: "testing",
  component: function component() {
    return import("@/views/testing/index.vue");
  },
  meta: {
    title: "检验检测"
  }
}, {
  path: "/testingDetails",
  name: "testingDetails",
  component: function component() {
    return import("@/views/testing/details.vue");
  },
  meta: {
    title: "检测详情"
  }
}, {
  path: "/hatch",
  name: "hatch",
  component: function component() {
    return import("@/views/hatch/index.vue");
  },
  meta: {
    title: "创业孵化"
  }
}, {
  path: "/hatchDetails",
  name: "hatchDetails",
  component: function component() {
    return import("@/views/hatch/details.vue");
  },
  meta: {
    title: "创业孵化详情"
  }
}, {
  path: "/technology",
  name: "technology",
  component: function component() {
    return import("@/views/technology/index.vue");
  },
  meta: {
    title: "技术转移"
  }
}, {
  path: "/technologyList",
  name: "technologyList",
  component: function component() {
    return import("@/views/technology/list.vue");
  },
  meta: {
    title: "技术转移列表"
  }
}, {
  path: "/release",
  name: "release",
  component: function component() {
    return import("@/views/technology/release.vue");
  },
  meta: {
    title: "发布技术转移",
    middleware: [auth]
  }
}, {
  path: "/technologyDetails",
  name: "technologyDetails",
  component: function component() {
    return import("@/views/technology/details.vue");
  },
  meta: {
    title: "技术转移详情"
  }
}, {
  path: "/exhibition",
  name: "exhibition",
  component: function component() {
    return import("@/views/exhibition/index.vue");
  },
  meta: {
    title: "企业展示"
  }
}, {
  path: "/exhibitionDetails",
  name: "exhibitionDetails",
  component: function component() {
    return import("@/views/exhibition/details.vue");
  },
  meta: {
    title: "企业详情"
  }
}, {
  path: "/interaction",
  name: "interaction",
  component: function component() {
    return import("@/views/interaction/index.vue");
  },
  meta: {
    title: "政企互动"
  }
}, {
  path: "/letterAdd",
  name: "letterAdd",
  component: function component() {
    return import('@/views/interaction/add');
  },
  meta: {
    title: "添加信件"
  }
}, {
  path: "/letters-write",
  name: "letterWrite",
  component: function component() {
    return import('@/views/interaction/write');
  },
  meta: {
    title: "我要写信"
  }
}, {
  path: "/letterList",
  name: "letterList",
  component: function component() {
    return import('@/views/interaction/list');
  },
  meta: {
    title: "信件列表"
  }
}, {
  path: "/lettersQuery",
  name: "lettersQuery",
  component: function component() {
    return import('@/views/interaction/query');
  },
  meta: {
    title: "信件查询"
  }
}, {
  path: "/lettersDetails",
  name: "lettersDetails",
  component: function component() {
    return import('@/views/interaction/details');
  },
  meta: {
    title: "信件详情"
  }
}, {
  path: "/introduce",
  name: "introduce",
  component: function component() {
    return import("@/views/introduce/index.vue");
  },
  meta: {
    title: "高新区简介"
  }
}, {
  path: "/user_details",
  name: "user_details",
  component: function component() {
    return import("@/views/user/details/index.vue");
  },
  meta: {
    title: "个人中心详情页面",
    middleware: [auth]
  }
}, {
  path: "/user",
  name: "user",
  component: function component() {
    return import("@/views/user/index.vue");
  },
  meta: {
    title: "个人中心",
    middleware: [auth]
  },
  children: [{
    path: 'personal',
    name: "personal",
    component: function component() {
      return import("@/views/user/personal.vue");
    },
    meta: {
      title: "个人信息"
    }
  }, {
    path: 'enterprise',
    name: "enterprise",
    component: function component() {
      return import("@/views/user/enterprise.vue");
    },
    meta: {
      title: "我的企业"
    }
  }, {
    path: 'order',
    name: "order",
    component: function component() {
      return import("@/views/user/order.vue");
    },
    meta: {
      title: "我的订单"
    }
  }, {
    path: 'myRelease',
    name: "myRelease",
    component: function component() {
      return import("@/views/user/release.vue");
    },
    meta: {
      title: "我的发布"
    }
  }, {
    path: 'invitation',
    name: "invitation",
    component: function component() {
      return import("@/views/user/invitation.vue");
    },
    meta: {
      title: "我的预约"
    }
  }, {
    path: 'information',
    name: "information",
    component: function component() {
      return import("@/views/user/information.vue");
    },
    meta: {
      title: "我的消息"
    }
  }, {
    path: 'collection',
    name: "collection",
    component: function component() {
      return import("@/views/user/collection.vue");
    },
    meta: {
      title: "我的收藏"
    }
  }]
}, {
  path: "/again",
  name: "again",
  component: function component() {
    return import("@/views/again.vue");
  },
  meta: {
    title: "空白页面"
  }
}, {
  path: "/error",
  name: "Error",
  component: function component() {
    return import("@/components/Error");
  },
  meta: {
    title: "错误页面",
    middleware: [auth]
  }
}];
var originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

var router = new VueRouter({
  mode: 'history',
  routes: routes
});
router.beforeEach(function (to, from, next) {
  // 每次跳转路由设置
  var token = getToken();

  if (!to.meta.middleware) {
    return next();
  }

  var middleware = to.meta.middleware;
  var context = {
    to: to,
    from: from,
    next: next,
    token: token
  };
  return middleware[0](_objectSpread({}, context));
});
export default router;