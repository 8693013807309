import service from "@/utils/request"; // 图形验证码

export function code() {
  return service({
    method: "get",
    url: "/auth/code"
  });
} // 注册

export function regists(data) {
  return service({
    method: "post",
    url: "/api/regist",
    data: data
  });
} // 登录

export function login(data) {
  return service({
    method: "post",
    url: "/auth/login",
    data: data
  });
} // 退出

export function out() {
  return service({
    method: "delete",
    url: "/auth/logout"
  });
}