//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import LayoutTop from "@/components/LayoutTop.vue";
import CountTo from "vue-count-to";
import { Statistics, visit, friendship, completeList, rotation } from "@/assets/api/public";
import { list as threeList } from "@/assets/api/Journalism";
import { list as sixLeftList } from "@/assets/api/policy";
import { getList as sixRightList } from "@/assets/api/letters";
import { list as sevenTopList } from "@/assets/api/enterprise";
import { cgList, xqList } from "@/assets/api/technology";
import { list as eightData } from "@/assets/api/finance";
export default {
  name: "Home",
  components: {
    LayoutTop: LayoutTop,
    CountTo: CountTo
  },
  data: function data() {
    return {
      oneList: [],
      twoList: [],
      Carousel: [],
      statute: "时政要闻",
      statuteTypeData: [{
        label: "时政要闻",
        id: 46
      }, {
        label: "通知公告",
        id: 48
      }],
      statuteId: 46,
      statuteData: [],
      sixLeft: [],
      sixRight: [],
      sevenTop: [],
      defaultImg: 'this.src="' + require("@/assets/img/qy.jpg") + '"',
      sevenBottomType: 2,
      sevenBottom: [],
      CarouselData: [],
      eightList: [],
      nineList: [],
      completeCg: [],
      completeXq: []
    };
  },
  computed: {
    defaultOption: function defaultOption() {
      return {
        step: 0.2,
        // 数值越大速度滚动越快
        limitMoveNum: 1,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)

      };
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    Statistics().then(function (res) {
      _this.twoList = res;
    });
    visit();
    threeList({
      type: 46,
      page: 0,
      size: 4,
      status: 2,
      sort: "releaseTime,desc",
      isShow: true,
      wheelPlanting: true
    }).then(function (res) {
      _this.Carousel = res.content;
    });
    threeList({
      type: 46,
      page: 0,
      size: 6,
      status: 2,
      sort: "releaseTime,desc",
      isShow: true
    }).then(function (res) {
      _this.statuteData = res.content;
    });
    sixLeftList({
      page: 0,
      size: 4,
      sort: "releaseTime,desc",
      openFlagopenFlag: true,
      status: 1
    }).then(function (res) {
      _this.sixLeft = res.content;
    });
    sixRightList({
      page: 0,
      size: 3,
      isShow: true,
      sort: ["createTime,desc"]
    }).then(function (res) {
      _this.sixRight = res.content;
    });
    sevenTopList({
      page: 0,
      size: 4,
      sort: "createTime,desc",
      isShow: true
    }).then(function (res) {
      _this.sevenTop = res.content;
    });
    xqList({
      page: 0,
      size: 6,
      sort: "createTime,desc",
      isShow: true,
      status: 2,
      demStatus: [3, 4]
    }).then(function (res) {
      _this.sevenBottom = res.content;
    });
    eightData({
      page: 0,
      size: 8,
      sort: "createTime,desc",
      isShow: true
    }).then(function (res) {
      _this.eightList = res.content;
    });
    friendship({
      page: 0,
      size: 4,
      sort: "orderNum,desc",
      isShow: true
    }).then(function (res) {
      _this.nineList = res.content;
    });
    completeList().then(function (res) {
      _this.CarouselData = res.demand;
      _this.completeCg = res.achievement;
      _this.completeXq = res.demand;
    });
    rotation({
      sort: "sort,desc"
    }).then(function (res) {
      _this.oneList = res.content;
    });
  },
  methods: {
    choiceStatute: function choiceStatute(item) {
      var _this2 = this;

      this.statute = item.label;
      this.statuteId = item.id;
      threeList({
        type: item.id,
        page: 0,
        size: 6,
        status: 2,
        sort: "createTime,desc",
        isShow: true
      }).then(function (res) {
        _this2.statuteData = res.content;
      });
    },
    clickSevenBottomType: function clickSevenBottomType(type) {
      var _this3 = this;

      this.sevenBottomType = type;

      if (type === 1) {
        cgList({
          page: 0,
          size: 6,
          sort: "createTime,desc",
          status: 2,
          isShow: true,
          demStatus: [3, 4]
        }).then(function (res) {
          _this3.sevenBottom = res.content;
          _this3.CarouselData = _this3.completeCg;
        });
      } else {
        xqList({
          page: 0,
          size: 6,
          sort: "createTime,desc",
          isShow: true,
          status: 2,
          demStatus: [3, 4]
        }).then(function (res) {
          _this3.sevenBottom = res.content;
          _this3.CarouselData = _this3.completeXq;
        });
      }
    },
    jump: function jump(url) {
      window.open(url);
    }
  }
};